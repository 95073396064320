import React from 'react';
import { Link } from "react-router-dom";

import { LinkContainer } from 'react-router-bootstrap'

import {
	Button
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

function About() {
	return (
	<>
		<main>
			<h2>Who are we?</h2>
			<p>
			That feels like an existential question, don't you
			think?
			</p>
		</main>
		<nav>
			<LinkContainer to="/">
				<Button>Home <FontAwesomeIcon icon={faCoffee} /></Button>
			</LinkContainer>
		</nav>
	</>
	);
}

export default About;
