import React, { useState } from 'react';

import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import {
	Button,
	Form,
	InputGroup,
	Alert,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const queryString = require('query-string');

function PasswordReset() {

	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showAlert, setShowAlert] = useState({});

	const submitEnabled = (password.length >= 8 && password.length <= 64);

	const doSubmit = async () => {

		setShowAlert({});

		let queryParams = window.location.search;

		let queryParsed = queryString.parse(queryParams);

		if (queryParsed.code)
		{
			try
			{
				const query = {
					mutation: {
						updateUserPasswordByPasswordReset: {
							__args: {
								passwordResetId: queryParsed.code,
								arg: { password }
							},
							code: true,
							message: true,
						}
					}
				};
		
				let fetchOptions = {
					method: 'POST',
					headers: {
					  Accept: 'application/json',
					  'Content-Type': 'application/json',
					},
					
					body: JSON.stringify({ query: jsonToGraphQLQuery(query, { pretty: false }) })
				};
					
				const response = await fetch('https://api.teamticker.com/graphql', fetchOptions);
				
				let json = await response.json();

				//console.log(JSON.stringify(json));
		
				if (json.data?.updateUserPasswordByPasswordReset?.code === 200)
				{
					setShowAlert({ variant: "success", message: "Your password has been reset." });
				}
				else
				{
					setShowAlert({ variant: "danger", message: "Password reset failed, try again later." });
				}
			}
			catch(err)
			{
				console.log("ERROR[refreshUser]: "+err);
			}	
		}
		else
		{
			setShowAlert({ variant: "danger", message: "URL is missing password reset code." });
		}
	};

	return (
	<>
		<div className="text-center">
			{
				showAlert.variant && 
				<Alert style={{ display: "inline-block" }} variant={ showAlert.variant } onClose={() => setShowAlert("")} dismissible>
					{ showAlert.message }
				</Alert>
			}
			<div className="passwordreset-container">
				<Form className="passwordreset-form">
					<Form.Group controlId="password">
						<Form.Label>{ "New Password" }</Form.Label>
						<InputGroup>
							<Form.Control 
								type={ showPassword?"text":"password" }
								name="password"
								onChange={ (evt) => { setPassword(evt.target.value) } }
								value={ password }
								/>
								<Button variant="primary-outline" tabIndex="-1" onClick={
									() => {
									setShowPassword(!showPassword);
									}
									}>
									<FontAwesomeIcon icon={ showPassword?faEyeSlash:faEye } />
								</Button>
						</InputGroup>
						<Form.Text className="text-muted password-rules-text">Must be at least 8 characters</Form.Text>
					</Form.Group>
					<Form.Group className="form-section-submit">
						<Button disabled={ !submitEnabled } onClick={ () => { doSubmit(); } }>Set Password</Button>
					</Form.Group>
				</Form>
			</div>
		</div>
	</>
	);
}

export default PasswordReset;
