import React from 'react';

function Footer() {

	let copyrightStr = "Copyright ©2021-##COPYRIGHTYEAR## TeamTicker. All rights reserved.".replace("##COPYRIGHTYEAR##", new Date().getFullYear());

	return (
	<>
		<div className="footer text-center">
			<hr/>
			<span>{ copyrightStr }</span>
		</div>
	</>
	);
}

export default Footer;
