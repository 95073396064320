import React from 'react';

function Warranty() {
	return (
	<>

<div className="app-policy">

<div className="title">Limited Warranty Policy</div>
<p>We take pride in our products. That is why we offer a limited warranty that covers any defects in material or workmanship under normal use during the warranty period. </p>
<p>During the warranty period we will repair or replace, at no charge, products or parts of a product that proves defective because of improper material or workmanship, under normal use and maintenance. </p>
<p>The warranty period for products purchased is 180 days from the date of purchase. A replacement product or part assumes the remaining warranty of the original product or 180 days from the date of replacement or repair, whichever is longer.</p>
<p>The limited warranty does not cover any problem that is caused by conditions, malfunctions or damage not resulting from defects in material or workmanship.</p>
<p>To obtain warranty service please contact us at:</p>
<p>support@teamticker.com</p>

</div>
	</>
	);
}

export default Warranty;
