import React from 'react';

function Header()
{
	return (
		<>
			<div className="header text-center">
				<img src={process.env.PUBLIC_URL + "images/logo-teamticker-single-red-r.svg"} style={{ width: "22em" }} />
				<hr />
			</div>
		</>
	);
}

export default Header;
