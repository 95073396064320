import React from 'react';
import { Routes, Route, Link, Navigate } from "react-router-dom";

import Header from "./views/Header";
import Footer from "./views/Footer";

import Home from "./views/Home";
import About from "./views/About";
import PasswordReset from "./views/PasswordReset";
import TermsAndConditions from "./views/TermsAndConditions";
import Warranty from "./views/Warranty";
import PrivacyPolicy from "./views/PrivacyPolicy";
import CodeOfConduct from "./views/CodeOfConduct";
import CorporateSocialResponsibility from "./views/CorporateSocialResponsibility";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App()
{

	const RedirectTermsOfUsePage = () =>
	{
		React.useEffect(() =>
		{
			window.location.replace('https://www.google.com')
		}, [])
	}

	const RedirectWarrantyPage = () =>
	{
		React.useEffect(() =>
		{
			window.location.replace('https://www.google.com')
		}, [])
	}

	return (
		<div className="container-fluid">
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/index.html" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/passwordreset" element={<PasswordReset />} />
				<Route path="/termsandconditions" element={<TermsAndConditions />} />
				<Route path="/warranty" element={<Warranty />} />
				<Route path="/privacypolicy" element={<PrivacyPolicy />} />
				<Route path="/codeofconduct" element={<CodeOfConduct />} />
				<Route path="/corporatesocialresponsibility" element={<CorporateSocialResponsibility />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
