import React from 'react';

function TermsOfUse() {
	return (
	<>

<div className="app-policy">

<div class="title">End-Use License Agreement and Terms of Use for TeamTicker Mobile App</div>
<p>Last updated: September 14, 2022</p>
<p>This End-User License Agreement and Terms of Use (“Terms”) states the terms and conditions under which you (“You” or “Your”) are permitted to download, install, and use the TeamTicker Application, which includes computer software and, as applicable, associated media, printed materials and on-line or electronic documentation, and includes any updates or new versions thereof (collectively, the “App”) provided to you by TeamTicker (“TeamTicker,” “the Company,” “We,” “Our,” or “Us”) or its resellers or distributors, and the terms and conditions under which You may sign up for an account through the App and receive the services provided through or in connection with the App (collectively, the “Service”), which consists of the selection and delivery of information to the App and/or to companion hardware.  Carefully read all the terms and conditions of these Terms before using the App and the Services.</p>
<p>BY DOWNLOADING, INSTALLING OR USING THE APP OR SERVICES, BY SIGNING UP FOR AN ACCOUNT, OR BY CLICKING THE “I AGREE” OR SIMILAR ICON, OR BY INSTALLING OR COPYING THE APP, YOU AGREE TO BE BOUND BY THE TERMS OF THESE TERMS BETWEEN TEAMTICKER AND YOU. IF YOU DO NOT AGREE TO THE TERMS OF THESE TERMS, DO NOT USE OR INSTALL OR COPY THE APP.</p>
<p>Any changes to these Terms will be included in a revised version of these Terms accessible through the App.  If You do not accept any revisions made to these Terms, You must stop using the App and Services. </p>
<h1>Interpretation and Definitions</h1>
<h2>Interpretation</h2>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h1>License Grant and Termination</h1>
<p>You may download, install, and use the App and the Service if You create and maintain an account with Us, including payment of any subscription fees by You or a third-party paying on Your behalf, and otherwise agree to and comply with these Terms.  Some features of the App will only be available with certain subscriptions, which may require payment of additional fees.</p>
<p>If you meet the requirements stated herein for download, installation, and use of the App and the Service, and otherwise comply with these Terms, TeamTicker hereby grants You a limited, revocable, nonexclusive, nontransferable license to use the App in object code (electronic) form only, in connection with the Service, contingent on your compliance with these Terms.</p>
<p>You may not, without Our prior written consent or as otherwise expressly authorized under these Terms:</p>
<ul><li>submit information that is untrue, harassing, abusive, threatening, advertising, soliciting, advocating an illegal activity, or in violation of any law;</li>
<li>access, download or copy any other user’s account information;</li>
<li>bypass any technical measures used to prevent or restrict access to any portion of the App;</li>
<li>violate or attempt to violate the security of the App or the proper working of the App;</li>
<li>engage in any unauthorized use of content and materials included on the App which, unless otherwise noted, is owned by Us;</li>
<li>use, copy, modify, reconfigure, divide, sublicense or distribute the App or related materials;</li>
<li>relicense, sublicense, rent, lease or lend the App or related materials or use the App or related materials for third-party training, hosting, or time sharing;</li>
<li>remove or modify from the App or related materials any markings or any notice of TeamTicker or its proprietary rights;</li>
<li>make the App or related materials available in any manner to any third party for use in the third party's business operations unless specifically permitted by Us;</li>
<li>cause or permit reverse engineering (unless required by law for interoperability), disassembly or decompilation of the App (the foregoing prohibition includes but is not limited to review of data structures or similar materials produced by the App);</li>
<li>disclose results of any program benchmark tests;</li>
<li>re-host or use the programs or related materials on equipment, operating systems or a platform other than the approved equipment.</li></ul>
<p> </p>
<p>All rights in the App and related materials not expressly granted in these Terms are reserved or retained by Us. No right or license exists, is granted, conferred, or may arise by implication or estoppel.</p>
<p>Your right to use the App shall terminate, and/or TeamTicker may at its option suspend or deactivate Your use of the App, upon Your failure to comply with any term or condition of these Terms, any unlawful use of the App and/or Service, or any abusive conduct involving the App and/or Service that is outside the normal contemplated use thereof. Upon termination of the licenses granted herein, You must cease all use of the App and destroy all copies (physical or electronic) of the App (including all component parts and copies thereof), and certify to Us, if so requested by Us, that the App has been so destroyed.</p>
<h1>Acknowledgement</h1>
<p>You represent that you are over the age of 18 and are the age of majority in the jurisdiction in which you reside. We do not permit those under 18 to use the Application or Service.</p>
<p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy [SO1] of TeamTicker. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the App or Service and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our App and Service, which is available at<u><a href=" https://app.teamticker.com/privacypolicy" rel="nofollow"> https://app.teamticker.com/privacypolicy</a></u></p>
<h1>Subscriptions</h1>
<h2>Subscription period</h2>
<p>The Service, or some parts of the Service, may be made available only with a paid Subscription. You will be billed in advance on a recurring and periodic basis (such as daily, weekly, monthly or annually), depending on the type of Subscription plan you select when purchasing the Subscription.</p>
<p>At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel it or We cancel it.</p>
<h2>Subscription cancellations</h2>
<p>You may cancel Your Subscription renewal either through Your Account settings page or by contacting Us. You will not receive a refund for the fees You already paid for Your current Subscription period, and You will be able to access the Service until the end of Your current Subscription period.</p>
<h2>Billing</h2>
<p>You shall provide Us with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information.</p>
<p>Should automatic billing fail to occur for any reason, We will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>
<h2>Fee Changes</h2>
<p>TeamTicker, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period.</p>
<p>We will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate Your Subscription before such change becomes effective.</p>
<p>Your continued use of the Service after the Subscription fee change comes into effect constitutes Your agreement to pay the modified Subscription fee amount.</p>
<h2>Refunds</h2>
<p>Except when required by law, paid Subscription fees are non-refundable.</p>
<p>Certain refund requests for Subscriptions may be considered by Us on a case-by-case basis and granted at the sole discretion of TeamTicker.</p>
<h2>Promotions</h2>
<p>Any promotions made available through the Service may be governed by rules that are separate from these Terms.</p>
<p>If You participate in any promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a promotion conflict with these Terms, the promotion rules will apply.</p>
<h1>User Accounts</h1>
<p>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of these Terms, which may result in immediate termination of Your account on Our Service.</p>
<p>You are responsible for maintaining the confidentiality of any password and account number or information provided by You or Us for accessing the Service and for any activities or actions under Your password.</p>
<p>You are the sole authorized user of Your account. Should You suspect that any unauthorized party may be using Your password or Account, or You suspect any other breach of security, You must notify Us immediately.</p>
<p>You may not use as an email address the email address of another person or entity that is not lawfully available for use, an email address that is subject to any rights of another person or entity other than You without appropriate authorization, or an email address that is otherwise offensive, vulgar or obscene.</p>
<h1>Intellectual Property</h1>
<p>The App and its original content (excluding content provided by you or other users), features and functionality are and will remain the exclusive property of TeamTicker and its licensors.</p>
<p>The App is protected by copyright, trademark, and other laws of both the United States and foreign countries.</p>
<p>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of us.</p>
<h1>Your Feedback to Us</h1>
<p>You assign all rights, title and interest in any feedback You provide Us. If for any reason such assignment is ineffective, You agree to grant Us a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such feedback without restriction.</p>
<h1>Links to Other Websites</h1>
<p>Our App or Service may contain links to third-party web sites or services that are not owned or controlled by Us.</p>
<p>TeamTicker has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that We shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
<p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
<h1>Limited Warranty and Disclaimer</h1>
<p>THE APP AND SERVICE LICENSED HEREIN IS LICENSED “AS IS” AND NEITHER TEAMTICKER NOR ITS REPRESENTATIVES OR DISTRIBUTORS MAKES ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE APP, ITS USE, THE SERVICE, OR THE RESULTS OF SUCH USE. ALL WARRANTIES AND CONDITIONS OF ANY KIND, PAST OR PRESENT, WHETHER STATUTORY, COMMON-LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, ARE HEREBY EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW.</p>
<p>Without limiting the foregoing, We do not warrant or represent that the operation of the App or Service will be uninterrupted or error-free, or that any defect within the App will be corrected. Furthermore, We do not warrant or make any representation regarding the results of Your use of the App or Service in terms of capability, correctness, accuracy, reliability or otherwise, or that the App or Service will meet Your requirements, or that the App or Service is secure. No representation, specimen, oral or written information or advice given by TeamTicker or any representative of Us shall create a warranty.</p>
<p>ANY LIABILITY OF TEAMTICKER OR ITS AFFILIATES FOR DEFECTS IN THE APP OR SERVICE WILL BE LIMITED EXCLUSIVELY TO REPLACEMENT OF YOUR COPY OF THE APP WITH ANOTHER COPY OF THE APP, IF AVAILABLE.</p>
<p>We cannot be responsible for the performance or maintenance of the computer or mobile-device's operating systems (Windows, Mac OS, iPhone OS, Android or other) that you employ. This includes bugs, viruses, spyware and other malware. Your operating system requires occasional security patches, updates, and service packs. You need to ensure that whenever possible it is set to update automatically and that it is receiving and installing updates and other maintenance releases. We also recommend that you install antivirus software where possible.</p>
<p>We cannot be responsible for other software installed on the system by the end user or that you choose to link to the App. We also cannot be responsible for any hardware, whether it was purchased from us or not. The reliability, security, and warranty of hardware is between you and the hardware manufacturer.</p>
<h1>Limitations and Indemnifications</h1>
<p>As the user of the App and Service, You are solely responsible for the selection of the App and Service to achieve Your intended results, for the installation and use made of the App, and for the results obtained from the App and its associated Service. You agree to follow the instructions contained in the App or materials provided from time to time in connection with the App, for installation and use of the App.</p>
<p>IN NO EVENT SHALL TEAMTICKER OR ITS RESELLERS, DISTRIBUTORS, EMPLOYEES, DIRECTORS, OWNERS, OR AFFILIATES (THE “TEAMTICKER PARTIES”) BE LIABLE WHETHER IN CONTRACT OR IN TORT, FOR ANY LOSSES, INJURIES OR DAMAGES OF ANY KIND, TO ANY PERSON OR ENTITY (INCLUDING WITHOUT LIMITATION, ANY DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL, MULTIPLE, EXEMPLARY, PUNITIVE OR INCIDENTAL DAMAGES WHATSOEVER) ARISING OUT OF OR RELATING TO ANY USE, INABILITY TO USE, CONDITION, PERFORMANCE, DEFECT OR FAILURE IN, OR IMPROPER USE OF, THE APP, THE SERVICE, OR THE RESULTS OF THE APP OR THE SERVICE (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFIT, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION OR OTHER PECUNIARY LOSS, OR DAMAGES FOR LOST OR CORRUPTED DATA), EVEN IF THE TEAMTICKER PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
<p>Some states or jurisdictions do not allow the exclusion or limitation of incidental, consequential or special damages, or the exclusion of implied warranties or limitations on how long a given warranty may last, so the above limitations may not apply to You. The limitations provided in these Terms are intended to apply to the fullest extent allowable under applicable law.</p>
<p>You release and waive all claims against the TeamTicker Parties, and the directors, trustees, officers, shareholders, employees, agents and representatives of each of the foregoing, from any and all claims, damages, liabilities, costs and expenses arising out of Your use of the App. If You are a California resident, You waive any rights You may have under §1542 of the California Civil Code, which reads: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.” You agree to release unknown claims and waive all available rights under California Civil Code §1542 or under any other statute or common law principle of similar effect. To the extent permitted by applicable law, this release covers all such claims regardless of the negligence of the TeamTicker Parties.</p>
<p>The device's operating system must not be compromised (for example: hacked, jail-broken, or otherwise tampered with). We assume no liability whatsoever from compromised or unlicensed devices running any App or using the Service.</p>
<p>You agree to indemnify, defend and hold harmless TeamTicker and its respective agents, affiliates, and their officers, directors and employees, from and against any claim, demand, damages, cost and expenses, including reasonable attorney fees, arising from or related to Your use of this App or the Service, or Your breach of any provision of these Terms or any warranty provided hereunder. </p>
<h1>Governing Law and Dispute Resolution</h1>
<p>These Terms shall be construed in accordance with the laws of the State of Ohio.  You agree that the exclusive jurisdiction for any claim or action arising out of or relating to these Terms shall be filed only in the state or federal courts located in Hamilton County, Ohio, and You further agree and submit to the exercise of personal jurisdiction of such courts for the purpose of litigating any such claim or action. Any disputes shall be decided via arbitration from a court of competent jurisdiction located in Hamilton County, Ohio and shall be subject to interpretation solely by a court of competent jurisdiction located in Hamilton County, Ohio.  </p>
<h1>United States Federal Government End Use Provisions</h1>
<p>If You are a U.S. federal government end user, our Service is a "Commercial Item" as that term is defined at 48 C.F.R. §2.101.</p>
<h2>United States Legal Compliance</h2>
<p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
<h1>Severability</h1>
<p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
<h1>Waiver</h1>
<p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.  These Terms constitutes the entire agreement and understanding between You and Us and govern your use of the App and Services, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of these Terms).</p>
<p>Any ambiguities in the interpretation of these Terms shall not be construed against the drafting party.</p>
<h1>Changes to These Terms and Conditions</h1>
<p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material, We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
<p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
<h1>Contact Us</h1>
<p>If you have any questions about these Terms and Conditions, You can contact us:</p>

<ul><li>By email: support@teamticker.com</li>
<li>By visiting this page on our website: <u><a href="https://www.teamticker.com/contact" rel="nofollow">https://www.teamticker.com/contact</a></u></li>
<li>By mail: TeamTicker 6809 Main Street #1052 Cincinnati, OH 45244</li></ul>
</div>
	</>
	);
}

export default TermsOfUse;
