import React from 'react';
import { Link } from "react-router-dom";

import { LinkContainer } from 'react-router-bootstrap'

import
{
	Button
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

function Home()
{
	return (
		<>
			<div className="text-center">
				<div className="coming-soon">Coming Soon!</div>
				<div className="home-tagline">“Changing the way fans connect with their favorite teams”</div>
			</div>
		</>
	);
}

export default Home;
