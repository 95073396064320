import React from 'react';

function Warranty()
{
	return (
		<>

			<div className="app-policy">

				<div class="title">Corporate Social Responsibility Policy for Clifton Bay Group LLC</div>
				<p>Last updated: January 1st, 2023</p>


				<h3>Introduction</h3>
				<p>For Clifton Bay Group, social responsibility means balancing social, economic, and environmental needs to find the best available solution which is essential to ensure a more  sustainable future.
				</p>
				<h3>Primary Objective</h3>
				<p>This policy aims to provide the framework for Clifton Bay Group and its subsidiaries to contribute positively to society and bring about sustainable change by leveraging all available resources and existing networks.
				</p>
				<h3>Policy Scope</h3>
				<p>This policy shall be integrated and applied to Clifton Bay Group and its subsidiaries which Clifton Bay Group has management control. Moreover, this policy should be promoted in any joint ventures and throughout the supply chain.
				</p>
				<h3>Definitions</h3>
				<p>We define Corporate Social Responsibility (CSR) as:</p>
				<ul>
					<li>Conducting business in a socially responsible and ethical manner.</li>
					<li>Protecting the environment and the safety of people.</li>
					<li>Supporting human rights.</li>
					<li>Engaging, respecting, supporting, and learning from, the communities and cultures with which we work.</li>
				</ul>

				<h3>Policy Statement</h3>
				<p>Clifton Bay Group will ensure that all matters of Corporate Social Responsibility are considered and supported in our operations and administrative matters and are consistent with stakeholders’ best interests.
				</p>
				<p>All Clifton Bay Group employees and contractors will adopt the Corporate Social Responsibility considerations described in this policy into their day-to-day work activities. Senior leadership will ensure that appropriate organizational structures are in place to effectively identify, monitor, and manage CSR issues and performance relevant to our business.
				</p>

				<h3>Business Ethics and Transparency</h3>
				<p>Clifton Bay Group is committed to maintaining the highest standards of corporate governance practices to maintain excellence in its daily operations.
				</p>
				<p>Clifton Bay Group will conduct its business in an open, honest, and ethical manner.
				</p>
				<p>Clifton Bay Group recognizes the importance of protecting all human, financial, physical, informational, social, environmental, and reputational assets.
				</p>
				<p>Clifton Bay Group will advise our partners, contractors, and suppliers of our Corporate Social Responsibility Policy and work with them to achieve consistency with this policy.
				</p>

				<h3>Environment Health and Safety</h3>
				<p>Clifton Bay Group is committed to protecting the health and safety of all individuals affected by our activities, including our employees, contractors, and the public. Clifton Bay Group will provide a safe and healthy working environment and will not compromise the health and safety of any individual. Our goal is to mitigate impacts on the environment by working with our stakeholders, peers, and others to promote responsible environmental practices and continuous improvement.
				</p>
				<p>Clifton Bay Group is committed to environmental protection and stewardship and recognizes that pollution prevention, biodiversity and resource conservation are key to a sustainable environment and will effectively integrate these concepts into our business decision-making.
				</p>
				<p>All employees are responsible and accountable for contributing to a safe working environment, for fostering safe working attitudes and for operating in an environmentally responsible manner.
				</p>

				<h3>Stakeholder Relations</h3>
				<p>Clifton Bay Group will engage stakeholders clearly, honestly, and respectfully.
				</p>
				<p>Clifton Bay Group is committed to timely and meaningful dialogue with all stakeholders including customers, employees, governments, and regulators among others.
				</p>

				<h3>Employee Relations</h3>
				<p>Clifton Bay Group will ensure that employees are treated fairly and with dignity and consideration for their goals and aspirations and that diversity in the workplace is embraced.
				</p>
				<p>Clifton Bay Group will apply fair labor practices while respecting the national and local laws of the countries and communities where we operate.
				</p>
				<p>Clifton Bay Group is committed to providing equal opportunity in all aspects of employment and will not engage in or tolerate unlawful workplace conduct, including discrimination, intimidation, or harassment.
				</p>

				<h3>Human Rights</h3>
				<p>Clifton Bay Group will work with all governments and agencies to support and respect human rights within our sphere of influence. Clifton Bay Group will not tolerate human rights abuses and will not engage or be complicit in any activity that solicits or encourages human rights abuses.
				</p>
				<p>Clifton Bay Group will always strive to build trust and mutually beneficial relationships while demonstrating respect for human dignity and rights in those relationships. This includes respect for cultures, customs, and values of individuals and groups.
				</p>

				<h3>Community Investment</h3>
				<p>Clifton Bay Group stresses collaborative, consultive, and partnership approaches in our community investment programs.
				</p>
				<p>Clifton Bay Group will integrate community considerations into decision-making and business practices and will assist in local partnership building to develop mutually beneficial relationships with communities.
				</p>
				<p>Clifton Bay Group will contribute in supporting local innovative programs in health, education, social services and the environment, as well as cultural and civic projects.
				</p>
				<p>Clifton Bay Group will strive to provide employment and economic opportunities in the communities where we operate.
				</p>

				<p><strong>The effectiveness of this policy will be monitored and reviewed at least annually by senior leadership to ensure the company’s continuing compliance with any relevant new business, to meet new business requirements, and to identify areas of improvement. Any changes to this policy will be communicated to all stakeholders involved.</strong></p>

			</div>
		</>
	);
}

export default Warranty;
