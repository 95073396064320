import React from 'react';

function Warranty()
{
	return (
		<>

			<div className="app-policy">

				<div class="title">Code of Conduct for Clifton Bay Group LLC</div>
				<p>Last updated: January 1st, 2023</p>

				<p>The Clifton Bay Group Code of Conduct defines labor standards that aim to achieve decent and humane working conditions. The code’s standards are based on International Labor Organization standards and internationally accepted good labor practices.</p>

				<p>Clifton Bay Group, and all companies affiliated with Clifton Bay Group, are expected to comply with all relevant and applicable laws and regulations of the country in which workers are employed and to implement the workplace code in their applicable facilities. When differences or conflicts in standards arise, affiliated companies are expected to apply the highest standard. Clifton Bay Group expects affiliated companies to make improvements when Code standards are not met and to develop sustainable mechanisms to ensure ongoing compliance.</p>

				<p>As a member of the <a href="https://www.fairlabor.org/" target="_blank">Fair Labor Association</a> (FLA) we adhere to the FLA Workplace Code of Conduct as detailed below.</p>
				<br />
				<h1>Workplace Code of Conduct</h1>
				<br />
				<h2>Employment Relationship</h2>
				<p>Employers shall adopt and adhere to rules and conditions of employment that respect workers and, at a minimum, safeguard their rights under national and international labor and social security laws and regulations.</p>
				<h2>Nondiscrimination</h2>
				<p>No person shall be subject to any discrimination in employment, including hiring, compensation, advancement, discipline, termination, or retirement, based on gender, race, religion, age, disability, sexual orientation, nationality, political opinion, social group, or ethnic origin.</p>
				<h2>Harassment or Abuse</h2>
				<p>Every employee shall be treated with respect and dignity. No employee shall be subject to any physical, sexual, psychological, or verbal harassment or abuse.</p>
				<h2>Forced Labor</h2>
				<p>There shall be no use of forced labor, including prison labor, indentured labor, bonded labor, or other forms of forced labor.</p>
				<h2>Child Labor</h2>
				<p>No person shall be employed under the age of 15 or under the age for completion of compulsory education, whichever is higher.</p>
				<h2>Women&apos;s Rights</h2>
				<p>Women workers will receive equal remuneration, including benefits: equal treatment; equal evaluation of the quality of their work; and equal opportunity to fill all positions open to male workers.</p>
				<p>Pregnancy tests will not be a condition of employment, nor will they be demanded of employees.</p>
				<p>Workers who take maternity leave will not face dismissal nor threat of dismissal, loss of seniority or deduction of wages, and will be able to return to their former employment at the same rate of pay and benefits.</p>
				<p>Workers will not be forced or pressured to use contraception. Workers will not be exposed to hazards that may endanger their safety, including their reproductive health.</p>
				<h2>Freedom of Association and Collective Bargaining</h2>
				<p>Employers shall recognize and respect the right of employees to freedom of association and collective bargaining.</p>
				<h2>Health, Safety & Environment</h2>
				<p>Employers shall provide a safe and healthy workplace setting to prevent accidents and injury to health arising out of, linked with, or occurring in the course of work or because of the operation of employers' facilities. Employers shall adopt responsible measures to mitigate negative impacts that the workplace has on the environment.</p>
				<h2>Hours of Work</h2>
				<p>Employers shall not require workers to work more than the regular and overtime hours allowed by the law of the country where the workers are employed. The regular work week shall not exceed 48 hours. Employers shall allow workers at least 24 consecutive hours of rest in every 7-day period. All overtime work shall be consensual. Employers shall not request overtime on a regular basis and shall compensate all overtime work at a premium rate. Other than in exceptional circumstances, the sum of regular and overtime hours in a week shall not exceed 60 hours.</p>
				<h2>Compensation</h2>
				<p>Every worker has a right to compensation for a regular work week that is sufficient to meet the worker’s basic needs and provide some discretionary income. Employers shall pay at least the minimum wage or the appropriate prevailing wage, whichever is higher, comply with all legal requirements on wages and provide any fringe benefits required by law or contract. Where compensation does not meet workers’ basic needs and provides some discretionary income, each employer shall work with the FLA to take appropriate actions that seek to progressively realize a level of compensation that does.</p>
				<br />
				<h1>Disclosure of Supply Chain Vendors</h1>
				<br />
				<h4>Shanghai Tongjie Printing Production Co., Ltd</h4>
				<p>
					<strong>Parent: </strong>Tongjie Group<br />
					<strong>Address: </strong>1650-B, Laifang Road, Jiuting Town, Songjiang Dist.<br />
					<strong>Function: </strong>Digital Printing and Light Machining<br />
					<strong>Employees: </strong>20-30
				</p>
				<h4>Guangzhou Haoshun Mold Tech Co., Ltd</h4>
				<p>
					<strong>Address: </strong>911, Heye Investment Building, 26 Asian Games Avenue<br />
					<strong>Function: </strong>Plastic Injection Molding Manufacturer<br />
					<strong>Employees: </strong>100-130
				</p>
				<h4>China PCB Win Technology Co.,Ltd</h4>
				<p>
					<strong>Parent: </strong>ShiYing Group<br />
					<strong>Address: </strong>Building 3,Tongfuyu Industrial Area,Wanxia Industrial Estate,Shajing Street,Baoan District<br />
					<strong>Function: </strong>Printed Circuit Board Manufacturer<br />
					<strong>Employees: </strong>51-200
				</p>
			</div >
		</>
	);
}

export default Warranty;
