import React from 'react';

function Warranty() {
	return (
	<>

<div className="app-policy">

<div class="title">Privacy Policy for TeamTicker Mobile App</div>
<p>Last updated: September 14, 2022</p>
<p>This Privacy Policy (“Policy”) describes how your personal information is collected, used, and shared when you download or use the TeamTicker Mobile Application, register an account with TeamTicker, or use any of the related services (collectively, the “App”).  Your prior activities on the App may have been governed by an earlier version of this Policy.  This App is intended for use in the United States.</p>
<h1>Personal Information We Collect</h1>
<p>While using the App, we will collect any information you provide to us to use the app, purchase a subscription, or register an account. This may include:</p>
<ul><li>Email address</li>
<li>Name</li>
<li>Phone number</li>
<li>Address</li></ul>
<p>We may also automatically collect certain information when you are using the app.  This may include information such as your device's IP address, browser type, browser version, the pages of our App that you visit, the time and date of your usage, the time spent using the App, unique device identifiers, other diagnostic data, the type of  device you use, your mobile device unique ID, operating system.</p>
<h1>Use of Your Personal Information</h1>
<p>We may use your Personal Information for the following purposes:</p>
<ul><li><strong>To provide and maintain the App</strong>, including to monitor the usage of the App.</li>
<li><strong>To manage your Account:</strong> to manage your registration as a user of the App.</li>
<li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services you have purchased or of any other contract with us through the App.</li>
<li><strong>To contact you:</strong> To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
<li><strong>To provide you</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.<sup>[SO1]</sup> </li>
<li><strong>To manage your requests:</strong> To attend and manage your requests to us.</li>
<li><strong>For business transfers:</strong> We may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our users is among the assets transferred.</li>
<li><strong>For other purposes</strong>: We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our App, products, services, marketing and your experience.</li></ul>
<h1>Sharing Your Personal Information</h1>
<p>We may share your Personal Information with third parties for various purposes, including: </p>
<ul><li><strong>With Service Providers:</strong> We may share your personal information with service providers who help us operate the App to monitor and analyze the use of the App, for payment processing, and to contact You.</li>
<li><strong>For business transfers:</strong> We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of our assets, financing, or acquisition of all or a portion of our business to another company.</li>
<li><strong>With Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners or other companies that we control or that are under common control with Us.</li>
<li><strong>With business partners:</strong> We may share your personal information with our business partners to offer you certain products, services or promotions.</li>
<li><strong>With other users:</strong> When you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. Similarly, other users will be able to view descriptions of your activity, communicate with you and view your profile.</li>
<li><strong>Law Enforcement</strong>: Under certain circumstances, we may be required to disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</li>
<li><strong>With Your consent</strong>: We may disclose your personal information for any other purpose with your consent.</li></ul>
<p>Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you and may collection Personal Information. </p>
<p>For more information regarding Shopify’s Terms of Service and Privacy Policy please follow these links:</p>
<p><u><a href="https://www.shopify.com/legal/terms" rel="nofollow">https://www.shopify.com/legal/terms</a></u>                                                           	    	                                                 <u><a href="https://www.shopify.com/legal/privacy" rel="nofollow">https://www.shopify.com/legal/privacy</a></u></p>
<p>We also use PayPal to help operate our website and process payments.  Any Personal Information collected by PayPal will be governed by their Privacy Policy, which can be viewed at<u><a href=" https://www.paypal.com/webapps/mpp/ua/privacy-full" rel="nofollow"> https://www.paypal.com/webapps/mpp/ua/privacy-full</a></u></p>
<p>We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
<p>We also use Google Analytics to help us understand how our customers use the Site.  As a result of your visit to our site, Google may collect information such as your domain type, your IP address and clickstream information.  We do not combine the information collected through the use of analytics tools with personally identifiable information.  You can read more about how Google uses your Personal Information here:<u><a href="  https://www.google.com/intl/en/policies/privacy/" rel="nofollow">  https://www.google.com/intl/en/policies/privacy/</a></u>.</p>
<p>You can also opt-out of Google Analytics here:<u><a href="  https://tools.google.com/dlpage/gaoptout" rel="nofollow">  https://tools.google.com/dlpage/gaoptout</a></u>.</p>
<p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
<h2>Behavioral Advertising</h2>
<p>We use remarketing services to advertise to you after you use the App. We and our third-party vendors use cookies and non-cookie technologies to help us recognize your device and understand how you use our App so that we can improve our service to reflect your interests and serve you advertisements that are likely to be of more interest to you.</p>
<p>These third-party vendors collect, store, use, process and transfer information about your activity on the App in accordance with their privacy policies and to enable us to:</p>
<ul><li>Measure and analyze traffic and browsing activity on Our Service</li>
<li>Show advertisements for our products and/or services to You on third-party websites or apps</li>
<li>Measure and analyze the performance of Our advertising campaigns</li></ul>
<p>You can learn more about the privacy policies of our third-party vendors and opt out of targeted advertising by visiting the following links</p>
<h4>Google Ads (AdWords)</h4>
<p><a href="https://www.google.com/settings/ads" rel="nofollow">https://www.google.com/settings/ads</a></p>
<p><u><a href="https://tools.google.com/dlpage/gaoptout" rel="nofollow">https://tools.google.com/dlpage/gaoptout</a></u>
</p>
<p><a href="https://policies.google.com/privacy" rel="nofollow">https://policies.google.com/privacy</a></p>
<h4>Twitter</h4>
<p><a href="https://support.twitter.com/articles/20170405" rel="nofollow">https://support.twitter.com/articles/20170405</a></p>
<p><a href="https://twitter.com/privacy" rel="nofollow">https://twitter.com/privacy</a></p>
<h4>Facebook</h4>
<p><a href="https://www.facebook.com/help/516147308587266" rel="nofollow">https://www.facebook.com/help/516147308587266</a></p>
<p><a href="https://www.facebook.com/help/568137493302217" rel="nofollow">https://www.facebook.com/help/568137493302217</a></p>
<p><a href="https://www.facebook.com/privacy/explanation" rel="nofollow">https://www.facebook.com/privacy/explanation</a></p>
<h4>Pinterest</h4>
<p><a href="https://help.pinterest.com/en/articles/personalization-and-data" rel="nofollow">https://help.pinterest.com/en/articles/personalization-and-data</a></p>
<p><a href="https://about.pinterest.com/en/privacy-policy" rel="nofollow">https://about.pinterest.com/en/privacy-policy</a></p>
<h2>Retention of Your Personal Data</h2>
<p>We will retain your Personal Information in accordance with our continuing need to service this relationship, to improve our site, and/or as may be required by law. </p>
<h2>Security of Your Personal Data</h2>
<p>The security of your personal information is important to us but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
<h1>Children’s Privacy</h1>
<p>By using this site, you represent that you are at least the age of majority in your state or province of residence.  This Site is not intended or designed to attract children.  We do not collect any information from any person we actually know is a child under the age of 13.</p>
<h1>Links to Other Websites</h1>
<p>The App may contain links to other websites or services that are not operated by us. If you click on a third-party link, you will be directed to that third party's site or service. We strongly advise you to review the privacy policy of every site or service you use.  We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
<h1>Changes to this Privacy Policy</h1>
<p>We may update this Policy from time to time. We will notify you of any changes by posting the new Policy and informing you of the changes the next time you open the App.</p>
<h1>Contact Us</h1>
<p>If you have any questions about this Policy, you can contact us:</p>
<ul><li>By email: support@teamticker.com</li>
<li>By visiting this page on our website: <u><a href="https://teamticker.com/contact" rel="nofollow">https://teamticker.com/contact</a></u></li>
<li>By mail: TeamTicker 6809 Main Street #1052 Cincinnati, OH 45244</li></ul>

</div>
	</>
	);
}

export default Warranty;
